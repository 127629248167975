<script>
import { reviewFieldTypes } from "../../utils/field-types";
import CloseButton from "@/components/general/CloseButton.vue";
import { scrollIntoView } from "@/utils/scroll-into-view";
import BooleanField from "./fields/BooleanField.vue";
import StringField from "./fields/StringField.vue";
import NumericField from "./fields/NumericField.vue";
import RatingField from "./fields/RatingField.vue";
import ItemDisplay from "./ItemDisplay.vue";
import RangeField from "./fields/RangeField.vue";

const maxFieldIndex = 999;

export default {
  components: {
    CloseButton,
    BooleanField,
    StringField,
    NumericField,
    RatingField,
    ItemDisplay,
    RangeField
  },
  inject: ["query", "tycka", "close"],
  props: {
    labels: {
      type: Object,
      required: true
    },
    language: {
      type: [String, null],
      default: null
    },
    languageHint: {
      type: [String, null],
      default: null
    },
    optionalFields: {
      type: Array,
      default() {
        return [];
      }
    },
    fieldOrder: {
      type: Object,
      default() {
        return {};
      }
    },
    customFieldMapOverrides: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      tyckaData: {},
      formDraft: {},
      invalidFields: [],
      expandedComments: {}
    };
  },
  computed: {
    formTitle() {
      return this.labels.formTitle;
    },
    formSubtitle() {
      return this.labels.formSubtitle;
    },
    commentPlaceholder() {
      return this.labels.commentPlaceholder || null;
    },
    itemId() {
      return this.query.itemId;
    },
    transactionId() {
      return this.query.transactionId;
    },
    secret() {
      return this.query.secret;
    },
    item() {
      return this.tyckaData && this.tyckaData.item;
    },
    fields() {
      if (!this.tyckaData.item) return {};
      return this.tyckaData.item.fields.reduce(
        (p, c) => (p[c.name] = c && p),
        {}
      );
    },
    transactionCustomFields() {
      if (!this.tyckaData || !this.tyckaData.transaction) return {};
      const fields = this.tyckaData.transaction.customFields || [];
      const result = fields.reduce((p, c) => {
        p[c.key] = c.value;
        return p;
      }, {});
      return result;
    },
    transactionName() {
      return this.getCustomFieldDataFor("name") || null;
    },
    transactionEmail() {
      return this.getCustomFieldDataFor("email") || null;
    },
    authorId() {
      return (
        this.tyckaData &&
        this.tyckaData.transaction &&
        this.tyckaData.transaction.authorId
      );
    },
    sortedFields() {
      if (!this.tyckaData.item) return [];

      function ratingFieldSortFunction(a, b) {
        return indexOrMax(a.name) - indexOrMax(b.name);
      }

      const indexOrMax = name => {
        const fieldOrder = this.fieldOrder[name];

        if (!isFinite(fieldOrder)) return maxFieldIndex;
        return fieldOrder;
      };

      let fieldsClone = [...this.tyckaData.item.fields];
      fieldsClone.sort(ratingFieldSortFunction);

      return fieldsClone;
    }
  },
  async mounted() {
    const result = await this.tycka.client.request(
      this.tycka.getTyckaDataForTransaction(this.tycka.alternativeNames),
      {
        itemId: this.itemId,
        transactionId: this.transactionId,
        secret: this.secret
      }
    );

    this.tyckaData = result;
    this.formDraft.name = this.transactionName;
    await this.$nextTick();
    this.expandRequiredComments();
    this.setFieldsFromTransactionData();
  },
  watch: {
    tyckaData(data) {
      this.$emit("tycka-data:updated", data);
      this.mutation = this.tycka.mutations.buildCreateReviewMutation(
        data.item.fields
      );
    }
  },
  methods: {
    expandRequiredComments() {
      for (var f of this.sortedFields) {
        if (f.type !== reviewFieldTypes.rating) continue;
        const commentKey = `${f.name}_comment`;

        if (!this.isFieldOptional(commentKey)) {
          this.expandComment(f.name);
        }
      }
    },
    setFieldsFromTransactionData() {
      for (var f of this.sortedFields) {
        if (f.type === reviewFieldTypes.rating) continue;
        const data = this.getCustomFieldDataFor(f.name);
        if (typeof data === "undefined") continue;

        switch (f.type) {
          case reviewFieldTypes.numeric:
          case reviewFieldTypes.range:
            this.formDraft[f.name] = +data;
            break;
          case reviewFieldTypes.boolean:
            this.formDraft[f.name] = !!data;
            break;
          default:
            this.formDraft[f.name] = data;
        }
      }
    },
    getLabel(key) {
      return this.labels[key] || key;
    },
    getLabelOrEmpty(key) {
      return this.labels[key] || "";
    },
    getErrorLabel(key) {
      return key;
    },
    expandComment(key) {
      this.expandedComments[key] = true;
    },
    isCommentExpanded(key) {
      return this.expandedComments[key];
    },
    clearValidationFor(key) {
      this.invalidFields = this.invalidFields.filter(f => f !== key);
    },
    errorMessageFor(key) {
      if (this.invalidFields.indexOf(key) !== -1) {
        return this.labels.errorRequired;
      }

      return null;
    },
    useTextInput(field) {
      return (
        [reviewFieldTypes.translatedText, reviewFieldTypes.text].indexOf(
          field.type
        ) !== -1
      );
    },
    useRatingInput(field) {
      return field.type === reviewFieldTypes.rating;
    },
    useCheckboxInput(field) {
      return field.type === reviewFieldTypes.boolean;
    },
    useNumericInput(field) {
      return field.type === reviewFieldTypes.numeric;
    },
    useRangeInput(field) {
      return field.type === reviewFieldTypes.range;
    },
    isFieldOptional(fieldName) {
      return this.optionalFields.some(f => f === fieldName);
    },
    getCustomFieldDataFor(fieldName) {
      const mappedFieldName =
        this.customFieldMapOverrides[fieldName] || fieldName;
      return this.transactionCustomFields[mappedFieldName];
    },
    updateDraftValueAndClearValidationError(key, value) {
      this.formDraft[key] = value;
      this.clearValidationFor(key);
    },
    async sendReview() {
      const payload = {
        itemId: this.itemId,
        transactionId: this.transactionId,
        transactionSecret: this.secret,
        email: this.transactionEmail,
        name: this.formDraft.name,
        languageHint: this.languageHint
      };

      if (this.authorId) {
        payload.authorId = this.authorId;
      }

      this.invalidFields = [];

      for (let f of this.sortedFields) {
        switch (f.type) {
          case reviewFieldTypes.rating:
            var commentKey = `${f.name}_comment`;
            if (!this.formDraft[f.name]) {
              this.invalidFields.push(f.name);
              continue;
            }

            if (
              !this.formDraft[commentKey] &&
              !this.isFieldOptional(commentKey)
            ) {
              this.invalidFields.push(commentKey);
              continue;
            }

            payload[f.name] = {
              value: this.formDraft[f.name] / 5,
              comment: this.formDraft[commentKey] || null
            };

            break;
          case reviewFieldTypes.numeric:
            if (
              this.formDraft[f.name] === "" ||
              !isFinite(this.formDraft[f.name])
            ) {
              if (!this.isFieldOptional(f.name)) {
                this.invalidFields.push(f.name);
              }
              continue;
            }

            payload[f.name] = +this.formDraft[f.name];
            break;
          case reviewFieldTypes.boolean:
            payload[f.name] = !!this.formDraft[f.name];

            break;
          default:
            if (!this.formDraft[f.name]) {
              if (!this.isFieldOptional(f.name)) {
                this.invalidFields.push(f.name);
              }
              continue;
            }
            payload[f.name] = this.formDraft[f.name];
            break;
        }
      }

      if (!this.formDraft.name) {
        this.invalidFields.push("name");
      }

      if (this.invalidFields.length) {
        this.$nextTick(() => {
          const form = document.querySelector(".general-form");
          const firstError = document.querySelector(".general-form--error");
          if (firstError) {
            scrollIntoView(form, firstError);
          }
        });
        return;
      }

      await this.tycka.client.request(this.mutation, payload);
      this.$emit("submitted-data:updated", payload);
    }
  },
  emits: ["submitted-data:updated", "tycka-data:updated"]
};
</script>

<template>
  <div class="general-form">
    <header class="general-form__header">
      <h1>{{ formTitle }}</h1>
      <p>{{ formSubtitle }}</p>
      <close-button class="general-form__close" @click="close" />
    </header>
    <item-display v-if="item" :item="item" :language="language" />
    <div class="input-fields">
      <div
        v-for="field in sortedFields"
        :key="field.name"
        class="input-field"
        :class="{
          'general-form--error':
            errorMessageFor(field.name) ||
            errorMessageFor(`${field.name}_comment`)
        }"
      >
        <rating-field
          v-if="useRatingInput(field)"
          :model-value="formDraft[field.name]"
          :comment="formDraft[`${field.name}_comment`]"
          @update:comment="
            updateDraftValueAndClearValidationError(
              `${field.name}_comment`,
              $event
            )
          "
          @update:modelValue="
            updateDraftValueAndClearValidationError(field.name, $event)
          "
          :comment-error-text="errorMessageFor(`${field.name}_comment`)"
          :error-text="errorMessageFor(field.name)"
          :label="getLabel(field.name)"
          :commentPlaceholder="commentPlaceholder"
          :comment-button-label="labels.addComment"
          :is-expanded="isCommentExpanded(field.name)"
          @expand:comment="expandComment(field.name)"
        />
        <numeric-field
          v-if="useNumericInput(field)"
          :label="getLabel(field.name)"
          :error-message="errorMessageFor(field.name)"
          v-model="formDraft[field.name]"
          @input="clearValidationFor(field.name)"
        />
        <range-field
          v-if="useRangeInput(field)"
          :label="getLabel(field.name)"
          :low-label="getLabelOrEmpty(field.name + '__low')"
          :mid-label="getLabelOrEmpty(field.name + '__mid')"
          :high-label="getLabelOrEmpty(field.name + '__high')"
          :error-message="errorMessageFor(field.name)"
          v-model="formDraft[field.name]"
          @input="clearValidationFor(field.name)"
        />
        <string-field
          v-if="useTextInput(field)"
          :error-message="errorMessageFor(field.name)"
          :label="getLabel(field.name)"
          v-model="formDraft[field.name]"
          @input="clearValidationFor(field.name)"
        />
        <boolean-field
          v-if="useCheckboxInput(field)"
          v-model="formDraft[field.name]"
          :label="getLabel(field.name)"
        />
      </div>
    </div>
    <div
      class="name-section"
      :class="{ 'general-form--error': errorMessageFor('name') }"
    >
      <div class="name-entry">
        <label class="name-label" for="fullName">{{ labels.name }}</label>
        <input
          class="name-input"
          id="fullName"
          type="text"
          :placeholder="labels.namePlaceholder"
          v-model="formDraft.name"
          @input="clearValidationFor('name')"
        />
        <div v-if="errorMessageFor('name')" class="name-section__error">
          {{ errorMessageFor("name") }}
        </div>
      </div>
    </div>
    <button @click="sendReview">{{ labels.sendReview }}</button>
  </div>
</template>

<style>
.general-form {
  text-align: center;
  padding-bottom: 20px;
  height: calc(100% - 20px);
  overflow: auto;
}

.general-form__header {
  background-color: #333;
  height: 110px;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.general-form__close.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.name-label {
  display: block;
  color: #0a1130;
  font-size: 16px;
  margin-bottom: 5px;
}

.name-input {
  width: calc(100% - 32px);
  padding: 7px 15px;
  border: 1px solid #e8e8e8;
  margin-bottom: 8px;
}

.name-section {
  text-align: left;
  margin: 20px;
}

.name-entry {
  position: relative;
}

.anonymous-checkbox {
  margin-bottom: 25px;
}

.measurement {
  margin: 20px 0 30px;
}

.measurement:last-child {
  margin-bottom: 8px;
}

.general-form button {
  color: white;
  background-color: #333333;
  width: calc(100% - 40px);
  font-size: 13px;
  padding: 13px;
  margin: 0 auto;
  text-transform: uppercase;
  border: 0;
  letter-spacing: 0.1em;
}

.rating-comment {
  color: #858897;
  font-size: 15px;
  margin: 10px 10px 15px;
}

.input-fields {
  display: inline-block;
  width: 100%;
  background-color: #f5f5f5;
  margin-bottom: 15px;
}

.input-field {
  text-align: center;
  margin: 10px;
  padding: 24px 15px;
  background-color: white;
  position: relative;
}

.input-field__error {
  position: absolute;
  bottom: 7px;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #f52214;
}

.name-section__error {
  font-size: 14px;
  color: #f52214;
  position: absolute;
  left: 0;
  bottom: -14px;
}
</style>
