<script>
import ErrorMessage from "../ErrorMessage.vue";

export default {
  components: {
    ErrorMessage
  },
  props: {
    label: {
      type: String,
      required: true
    },
    modelValue: {
      type: Number,
      default: 0
    },
    errorMessage: {
      type: String,
      default: null
    },
    lowLabel: {
      type: String,
      default: ""
    },
    midLabel: {
      type: String,
      default: ""
    },
    highLabel: {
      type: String,
      default: ""
    }
  },
  emits: ["update:model-value"]
};
</script>

<template>
  <div class="range-field">
    <h2>{{ label }}</h2>
    <div class="range-field__labels">
      <span class="range-field__label">{{ lowLabel }}</span>
      <span class="range-field__label">{{ midLabel }}</span>
      <span class="range-field__label">{{ highLabel }}</span>
    </div>
    <div class="range-field__container">
      <input
        class="range-field__input"
        type="range"
        min="-1.0"
        max="1.0"
        step="0.1"
        :value="modelValue"
        @input="$emit('update:model-value', parseFloat($event.target.value))"
      />
    </div>
    <error-message class="range-field__error" :text="errorMessage" />
  </div>
</template>

<style>
.range-field__container {
  display: flex;
  align-items: center;
}

.range-field__input {
  width: 100%;
  -webkit-appearance: none;
  height: 4px;
  background: #333;
  border-radius: 2px;
}

.range-field__input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #333;
}

.range-field__input::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #333;
}

.range-field__error {
  margin-top: 10px;
}

.range-field__labels {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.range-field__label {
  font-size: 0.9em;
  color: #666;
}
</style>
